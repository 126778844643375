import { Text, SimpleGrid, Container, rem, Title, Paper } from "@mantine/core";
import { useFeaturesStyles } from "./Features.styles";
import {
  MdOutlineMarkEmailRead,
  MdReceiptLong,
  MdPayment,
} from "react-icons/md";
import { BiWallet } from "react-icons/bi";
import { RiSecurePaymentFill } from "react-icons/ri";
import { VscServerProcess } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

const Features = () => {
  const { classes } = useFeaturesStyles();
  const { t } = useTranslation();

  const data = [
    {
      icon: MdPayment,
      title: t("features.featureOneTitle"),
      description: t("features.featureOneDescription"),
    },
    {
      icon: MdOutlineMarkEmailRead,
      title: t("features.featureTwoTitle"),
      description: t("features.featureTwoDescription"),
    },
    {
      icon: VscServerProcess,
      title: t("features.featureThreeTitle"),
      description: t("features.featureThreeDescription"),
    },
    {
      icon: MdReceiptLong,
      title: t("features.featureFourTitle"),
      description: t("features.featureFourDescription"),
    },
    {
      icon: RiSecurePaymentFill,
      title: t("features.featureFiveTitle"),
      description: t("features.featureFiveDescription"),
    },
    {
      icon: BiWallet,
      title: t("features.featureSixTitle"),
      description: t("features.featureSixDescription"),
    },
  ];

  const items = data.map((item, index) => (
    <Paper key={index} className={classes.feature} shadow="lg" radius="md">
      <div className={classes.overlay} />

      <div className={classes.content}>
        <item.icon size={rem(38)} className={classes.icon} stroke="1.5" />
        <Title
          order={3}
          fw={700}
          fz="lg"
          mb="lg"
          mt={5}
          className={classes.title}
        >
          {item.title}
        </Title>
        <Text c="dimmed" fz="sm">
          {item.description}
        </Text>
      </div>
    </Paper>
  ));

  return (
    <Container mt={30} mb={30} size="lg">
      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: "lg", cols: 3, spacing: 30, verticalSpacing: 30 },
          { maxWidth: "md", cols: 2, spacing: 30, verticalSpacing: 30 },
          { maxWidth: "sm", cols: 1, verticalSpacing: 35 },
        ]}
        spacing={45}
        verticalSpacing={45}
      >
        {items}
      </SimpleGrid>
    </Container>
  );
};

export default Features;
