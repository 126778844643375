import { Container, SimpleGrid, Stack, Text, Title, Flex } from "@mantine/core";
import { useTechFlowStyles } from "./TechFlow.styles";
import { HiOutlineLink, HiOutlineClipboardList } from "react-icons/hi";
import {
  MdOutlineMarkEmailRead,
  MdPayment,
  MdOutlineMailOutline,
} from "react-icons/md";
import { VscServerProcess } from "react-icons/vsc";
import { LuClock2 } from "react-icons/lu";
import { AiOutlineBank } from "react-icons/ai";
import { TbLicense } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import Flow from "./Flow";

const TechFlow = () => {
  const { classes } = useTechFlowStyles();
  const { t } = useTranslation();

  const flowsData = [
    {
      title: t("flow.link4Pay.title"),
      description: t("flow.link4Pay.description"),
      active: 3,
      color: "green",
      time: t("flow.minute", { count: 2 }),
      steps: [
        {
          title: t("flow.link4Pay.generateLinkTitle"),
          description: t("flow.link4Pay.generateLinkDescription"),
          time: t("flow.minute", { count: 1 }),
          variant: "dotted" as "dotted",
          icon: HiOutlineLink,
        },
        {
          title: t("flow.link4Pay.emailTransmissionTitle"),
          description: t("flow.link4Pay.emailTransmissionDescription"),
          time: "instant",
          variant: "dotted" as "dotted",
          icon: MdOutlineMarkEmailRead,
        },
        {
          title: t("flow.link4Pay.onlinePaymentTitle"),
          description: t("flow.link4Pay.onlinePaymentDescription"),
          time: t("flow.minute", { count: 1 }),
          variant: "dotted" as "dotted",
          icon: MdPayment,
        },
        {
          title: t("flow.link4Pay.automaticProcessTitle"),
          description: t("flow.link4Pay.automaticProcessDescription"),
          time: "instant",
          variant: "dotted" as "dotted",
          icon: VscServerProcess,
        },
      ],
    },
    {
      title: t("flow.classic.title"),
      description: t("flow.classic.description"),
      active: 5,
      color: "pink",
      time: t("flow.minute", { count: 20 }),
      steps: [
        {
          title: t("flow.classic.emailTransmissionTitle"),
          description: "",
          time: t("flow.minute", { count: 2 }),
          variant: "dotted" as "dotted",
          icon: MdOutlineMailOutline,
        },
        {
          title: t("flow.classic.paymentTitle"),
          description: "",
          time: t("flow.minute", { count: 5 }),
          variant: "dotted" as "dotted",
          icon: AiOutlineBank,
        },
        {
          title: t("flow.classic.checkBankStatementTitle"),
          description: "",
          time: t("flow.minute", { count: 3 }),
          variant: "dotted" as "dotted",
          icon: HiOutlineClipboardList,
        },
        {
          title: t("flow.classic.paymentHandleTitle"),
          description: "",
          time: t("flow.minute", { count: 5 }),
          variant: "dotted" as "dotted",
          icon: MdPayment,
        },
        {
          title: t("flow.classic.issueInvoiceTitle"),
          description: "",
          time: t("flow.minute", { count: 3 }),
          variant: "dotted" as "dotted",
          icon: TbLicense,
        },
        {
          title: t("flow.classic.emailConfirmationTitle"),
          description: "",
          time: t("flow.minute", { count: 2 }),
          variant: "dotted" as "dotted",
          icon: MdOutlineMarkEmailRead,
        },
      ],
    },
  ];

  const flows = flowsData.map((flow, index) => (
    <Stack key={index} justify="space-between">
      <Stack spacing={0}>
        <Title
          order={3}
          fz={24}
          className={`${classes[flow.color + "CardTitle"]}`}
        >
          {flow.title}
        </Title>
        <Text component="p" color="dimmed" size="sm" align="left" mt={8} mb={4}>
          {flow.description}
        </Text>
        <Flex align="center" gap="xs" mt="2px">
          <LuClock2 size={18} />
          <Text fw={600}>
            {flow.time}
            <Text component="span" ml="4px">
              {t("flow.perTransaction")}
            </Text>
          </Text>
        </Flex>
      </Stack>

      <Flow items={flow.steps} active={flow.active} color={flow.color} />
    </Stack>
  ));

  return (
    <Container mt={120} size="lg">
      <Title order={3} className={classes.sectionTitle}>
        {t("flow.title")}
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        {t("flow.description")}
      </Text>
      <SimpleGrid
        cols={2}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
        spacing={50}
        mt={30}
      >
        {flows}
      </SimpleGrid>
    </Container>
  );
};

export default TechFlow;
