import { createStyles, rem } from "@mantine/core";

export const useProcessorsStyles = createStyles((theme) => {
  return {
    carouselRoot: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 10,
    },

    carouselControl: {
      backgroundColor: "transparent",
      border: "none",
      "& svg": {
        width: "1.5rem",
        height: "1.5rem",
      },
    },

    carouselIndicator: {
      width: rem(12),
      height: rem(4),
      backgroundColor: "#74c0fc",
      transition: "width 250ms ease",
      "&[data-active]": {
        width: rem(40),
        backgroundColor: "#228be6",
      },
    },

    clientLogo: {
      maxWidth: "180px",
      maxHeight: "120px",
      cursor: "pointer",
      objectFit: "contain",
    },
  };
});
