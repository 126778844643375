import { Title, Text, Container, Box } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";
import { Dots } from "../../assets";
import { useHeroBannerStyles } from "./HeroBanner.styles";

const Banner = () => {
  const { classes } = useHeroBannerStyles();
  const { t } = useTranslation();

  return (
    <Box w="100%" style={{ overflow: "hidden" }}>
      <Container className={classes.wrapper} size={1400}>
        <Dots className={classes.dots} style={{ left: -220, top: 0 }} />
        <Dots className={classes.dots} style={{ left: -20, top: 0 }} />
        <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
        <Dots className={classes.dots} style={{ left: -220, top: 140 }} />
        <Dots className={classes.dots} style={{ left: -20, top: 140 }} />
        <Dots className={classes.dots} style={{ right: -20, top: 40 }} />
        <Dots className={classes.dots} style={{ right: -20, top: 0 }} />
        <Dots className={classes.dots} style={{ right: -220, top: 40 }} />
        <Dots className={classes.dots} style={{ right: -220, top: 0 }} />
        <Dots className={classes.dots} style={{ right: -220, top: 140 }} />

        <div className={classes.inner}>
          <Title order={1} className={classes.title}>
            <Trans
              i18nKey="banner.title"
              components={{
                CustomText: (
                  <Text
                    component="span"
                    className={classes.highlight}
                    inherit
                  />
                ),
              }}
            />
          </Title>
          <Container p={0} size={600}>
            <Text size="lg" color="dimmed" className={classes.description}>
              {t("banner.description")}
            </Text>
          </Container>
        </div>
      </Container>
    </Box>
  );
};

export default Banner;
