import { createStyles, rem } from "@mantine/core";

export const useFeaturesStyles = createStyles((theme) => ({
  feature: {
    position: "relative",
    padding: theme.spacing.xl,
  },

  overlay: {
    position: "absolute",
    height: rem(110),
    width: "100%",
    top: 0,
    left: 0,
    borderRadius: "0.5rem 0.5rem 0 0",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: "blue",
    }).background,
    zIndex: 1,
  },

  content: {
    position: "relative",
    zIndex: 2,
  },

  icon: {
    color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
      .color,
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));
