import { createStyles } from "@mantine/core";

export const useLayoutStyles = createStyles((theme) => ({
  main: {
    minHeight: "calc(100vh - 60px)",
    padding: "68px 2px 60px 2px",
    [theme.fn.smallerThan("md")]: {
      padding: "68px 16px 60px 16px",
    },
  },
}));
