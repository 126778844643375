import React from "react";
import { Contact, Banner, Features, TechFlow } from "../components";
import PaymentProcessors from "../components/Processors/PaymentProcessors";

type Props = {};

const LandingPage = (props: Props) => {
  return (
    <>
      <Banner />
      <Features />
      <TechFlow />
      <PaymentProcessors />
      <Contact />
    </>
  );
};

export default LandingPage;
