import { createStyles } from "@mantine/core";

export const useTechFlowStyles = createStyles((theme) => ({
  sectionTitle: {
    textAlign: "center",
  },

  greenCardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.colors.green[6],
      width: 45,
      height: 2,
      marginTop: theme.spacing.xs,
    },
  },

  pinkCardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.colors.pink[6],
      width: 45,
      height: 2,
      marginTop: theme.spacing.xs,
    },
  },
})) as any;
