import { Text, Timeline } from "@mantine/core";
import { TimelineItem } from "../../model/Flow";

interface ItemProps {
  items: TimelineItem[];
  active: number;
  color: string;
}

const Flow = (props: ItemProps) => {
  const timeline = props.items.map((item, index) => (
    <Timeline.Item
      key={index}
      bullet={<item.icon size={16} />}
      title={item.title}
      lineVariant={item.variant}
      color={props.color}
    >
      <Text color="dimmed" size="sm">
        {item.description}
      </Text>
      <Text size="xs" mt={4}>
        {item.time}
      </Text>
    </Timeline.Item>
  ));

  return (
    <Timeline active={props.active} bulletSize={30} lineWidth={2}>
      {timeline}
    </Timeline>
  );
};

export default Flow;
