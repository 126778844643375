import { Carousel } from "@mantine/carousel";
import { Flex, Text, Title, Container } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Bt, Ing, Netopia, EuPlatesc } from "../../assets/payment-processors";
import { useProcessorsStyles } from "./Processors.styles";

const clients = [
  {
    name: "BT iPay",
    img: Bt,
    link: "https://btepos.ro/module-ecommerce",
  },
  {
    name: "Netopia",
    img: Netopia,
    link: "https://netopia-payments.com/",
  },
  {
    name: "EuPlătesc",
    img: EuPlatesc,
    link: "https://www.euplatesc.ro/",
  },
  {
    name: "ING WebPay",
    img: Ing,
    link: "https://ing.ro/imm/operatiuni-curente/acceptare-carduri/ecommerce",
  },
];

const PaymentProcessors = () => {
  const { classes } = useProcessorsStyles();
  const { t } = useTranslation();

  const handleLinkClick = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const slides = clients.map((slide, index) => (
    <Carousel.Slide key={`${slide.name}-${index}`}>
      <Flex
        direction="column"
        justify="center"
        align="center"
        w="100%"
        h="100%"
        gap="xs"
      >
        <Flex h="120px">
          <img
            onClick={() => handleLinkClick(slide.link)}
            src={slide.img}
            alt={slide.name}
            className={classes.clientLogo}
          />
        </Flex>
      </Flex>
    </Carousel.Slide>
  ));

  const CarouselProps = {
    controlSize: 36,
    classNames: {
      control: classes.carouselControl,
      indicator: classes.carouselIndicator,
      root: classes.carouselRoot,
    },
    height: 300,
    slideSize: "33.3333%",
    slideGap: "md",
    align: "start" as "start",
    loop: true,
    breakpoints: [
      { maxWidth: "md", slideSize: "50%" },
      { maxWidth: "sm", slideSize: "100%", slideGap: "md" },
    ],
    withControls: true,
    withIndicators: true,
    draggable: true,
  };

  return (
    <Container mt={120} size="lg">
      <Flex
        direction="column"
        align="center"
        mb="350px"
        style={{ position: "relative" }}
      >
        <Title order={3}>
          {t("processors.title")}
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          {t("processors.description")}
        </Text>
        <Carousel {...CarouselProps}>{slides}</Carousel>
      </Flex>
    </Container>
  );
};

export default PaymentProcessors;
